.card {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px;
    width: 400px;
}

.card-title {
    font-size: 1em;
    font-weight: bold;
}

.card-subtitle {
    font-size: 1em;
    color: #777;
}

.card-subtitle button {
    background: none;
    color: #777;
    padding: 0;
}

.card-subtitle button::after {
    display: none;
}

.card-subtitle .dropdown-menu {
    min-width: 100px;
    right: 0;
}

.card-subtitle .dropdown-item {
    padding: 4px 10px;
}


.card-icon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 12px;
}

.card-content {
    margin-top: 10px;
}

.card-action {
    margin-top: 20px;
    text-align: center;
}

.dispatch {
    width: inherit;
}

.dispatch img {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: #fff 1px solid;
}

.start-btn {
    width: inherit;
}

.start-btn button {
    border: #605D62 1px solid;
    background: #fff;
    padding: 5.5px 25px;
    border-radius: 180px;
    color: #20456A;
    font-size: 16px;
    font-weight: 600;
    margin-left: 300%;
}

.start-btn button:hover {
    border: #1F578C 1px solid;
    color: #fff;
    background: #1F578C;
}

.start {
    border: #605D62 1px solid;
    background: #fff;
    padding: 5.5px 25px;
    border-radius: 180px;
    color: #20456A;
    font-size: 16px;
    font-weight: 600;
}

.start:hover {
    border: #1F578C 1px solid;
    color: #fff;
    background: #1F578C;
}

.mdl-sec h4 {
    margin-bottom: 40px;
}


.btn {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    /* display: inline-block; */
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
}

.btn:hover {
    background-color: #3e8e41;
}

.pause {
    color: #939094;
    margin-right: 4px;
}

.status {
    font-weight: 400;
}

.start {
    border-radius: 25px;
    color: #002b54;
    background: white;
    padding: 5px 25px;
}

.start:hover {
    background: #1f578c;
    color: white;
}

.integration strong {
    color: #605D62;
    font-size: 16px;
    font-weight: 700;
}

.not-start svg {
    margin-right: 5px;
}


.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-toggle {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.dropdown-menu {
    display: block;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.dropdown-item {
    padding: 10px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f1f1f1;
}