/* Loader Css Start PSR*/

.loader-container {
  position: fixed !important;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.loader-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

.loader-content {
  position: relative;
  height: 100vh;
}

.sc-blHHSb.doJlBZ {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

/* Loader Css End PSR*/


.formDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 30px 65px 35px 60px;
}

body {
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "slnt"0;
  background: #E5E0EF;
  overflow-x: hidden !important;
}



.heading1 {
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e1e1e1;
  line-height: 1;
  color: #35254D;
}

.container.all-steps {
  background: white;
  border: 0 none;
  border-radius: 15px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.16);
  padding: 35px 50px 50px 50px;
  box-sizing: border-box;
  width: 89%;
  max-width: 780px;
  margin: 30px auto 50px auto;
  position: relative;
}

.heading-container {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.heading-text {
  display: grid;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 20px;
}



.container h2 {
  display: flex;
  margin: 0;
  color: #35254D;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -1px;
}

.container h5 {
  display: flex;
  margin: 0;
  color: #9A92A6;
  font-size: 14px;
  font-weight: 500;
}

.labelStyle {
  display: block;
  margin: 0 0 7px 0;
  line-height: 1;
  color: rgba(53, 37, 77, 0.6);
  font-size: 12px;
  margin: 10px;
  font-family: "IBM Plex Mono", monospace;
  text-transform: uppercase;
  font-weight: 500;
}

select, option::placeholder {
  display: block !important;
  margin: 0 0 7px 0 !important;
  line-height: 1 !important;
  color: #999 !important;
  font-size: 12px !important;
}

.react-datepicker {
  border: #E5E4E8 1px solid !important;
}

.react-datepicker__input-container {
  position: relative;
}

.react-datepicker__input-container:before {
  width: 16px;
  height: 16px;
  right: 20px;
  background: url(../public/date-ico.png);
  z-index: 9999;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  content: '';
}

.react-datepicker__header {
  background: none !important;
  border: 0 !important;
}

.react-datepicker__current-month {
  padding: 12px 0 !important;
  color: #35254D !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
}

.react-datepicker__navigation-icon::before {
  border-color: #9A92A6 !important;
  border-width: 2px 2px 0 0 !important;
  height: 6px !important;
  top: 20px !important;
  width: 6px !important;
}

.react-datepicker__day-name {
  font-family: "IBM Plex Mono", monospace !important;
  text-transform: uppercase !important;
  color: #A4AAB2 !important;
}

.react-datepicker__day {
  color: #3B3C3E !important;
}

.react-datepicker__day--selected {
  background: #35254D !important;
  color: #fff !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 1.9rem !important;
  line-height: 1.9rem !important;
  margin: 2px !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  fill: #fff !important;
  stroke: #E5E4E8 !important;
}

.Btn1div {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;
}

.Btn1div button {
  padding: 10px 20px;
  min-height: 56px;
  max-height: 56px;
  margin-top: 0;
  margin-bottom: 0;

}

.ownership-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ownership-text span {
  font-size: 12px;
}

.ownership-text button {
  border: #D4CDDF 1px solid;
  border-radius: 180px;
  padding: 8px 20px;
  margin: 0 0 0 6px;
  color: #35254D;
  font-size: 12px;
  background: #fff;
}

.ownership-text button:hover {
  color: #D4CDDF;
  background: #35254D;
  border: #35254D 1px solid;
}

.PrevBtn {
  background: #fff;
  font-weight: bold;
  color: #000;
  border: 0 none;
  border-radius: 180px;
  cursor: pointer;
  padding: 20px 45px;
  width: 50%;
  margin: 1%;
  border: #ddd 1px solid;
}


.fields {
  width: calc(100% - 16px);
  border-radius: 6px !important;
  border: 1px solid #E5E4E8 !important;
  padding: 0 1rem;
  font-size: 14px !important;
  height: 2.75rem;
  line-height: 1.5;
  margin-left: 0.5rem;
  box-sizing: border-box;
  color: #35254D;
  font-family: "Inter", sans-serif !important;
}

select.fields {
  color: #35254D !important;
  width: 97%;
  border-radius: 2px;
  border: 1px solid #e1e1e1;
  padding: 0px 2% 0px 2%;
  height: 46px;
  line-height: 1;
  margin-left: 8px !important;
}

.fields::placeholder {
  color: #999;
  width: 100%;
  padding: 11px 0;
  height: 44px;
  line-height: 1;
  font-size: 12px;
}

.fields option {
  color: #333;
  padding: 11px 15px;
  height: 44px;
  line-height: 1;
  font-size: 12px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1* var(--bs-gutter-y));
  margin-right: calc(-.5* var(--bs-gutter-x));
  margin-left: calc(-.5* var(--bs-gutter-x));
}

.input-coll {
  flex: 0 0 auto;
  width: 50%;
  margin-bottom: 15px;
  position: relative;
}

.input-coll-full {
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
}

.input-coll .react-datepicker-wrapper {
  width: 100%;

}



.para {
  color: #999;
  width: 100%;
  line-height: 24px;
  font-size: 17px;
  margin-bottom: 20px;
}

.box-with-shadow {
  padding: 15px;
  box-shadow: 0px 0px 4px 0px #c1b4b4;
  margin-bottom: 10px;
}

.mt-all {
  margin: 15px 0 10px 0 !important;
}

.termsConditions {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  padding: 7px 5px 4px 0;
  font-size: 13px !important;
  line-height: 17px !important;
  margin-bottom: 10px;
}

.termsConditions {
  font-size: 15px;
}

.termsConditions label {
  font-size: 15px;
  color: #35254D;
  margin-left: 8px;
}

.termsConditions label a {
  color: #867C94;
  text-decoration: none;
}

.chooseFile small {
  color: #867C94;
  border-bottom: dotted #35254D 1px;
  font-size: 16px;
}

.chooseFile {
  flex-direction: row;
  cursor: pointer;
  text-align: center;
  padding: 30px;
  border-radius: 8px;
  min-height: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-image: repeating-linear-gradient(14deg, #cccad3, #cccad3 11.16px, transparent 12px, transparent 24.09px, #cccad3 25px), repeating-linear-gradient(104deg, #cccad3, #cccad3 11.16px, transparent 12px, transparent 24.09px, #cccad3 25px), repeating-linear-gradient(194deg, #cccad3, #cccad3 11.16px, transparent 12px, transparent 24.09px, #cccad3 25px), repeating-linear-gradient(284deg, #cccad3, #cccad3 11.16px, transparent 12px, transparent 24.09px, #cccad3 25px);
  background-size: 1px calc(100% + 25.77px), calc(100% + 25.77px) 1px, 1px calc(100% + 25.77px), calc(100% + 25.77px) 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  animation: borderAnimation 1s infinite linear;
}

@keyframes borderAnimation {
  from {
    background-position: 0 0, -25.77px 0, 100% -25.77px, 0 100%;
  }

  to {
    background-position: 0 -25.77px, 0 0, 100% 0, -25.77px 100%;
  }



}

.file-label {
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  font-size: 12px;
  cursor: pointer;
  color: #151516;
  display: block !important;
}

.file-name.file-name-sec .file-name span {
  border: #D4CDDF 1px solid;
  border-radius: 8px;
  margin-top: 12px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-name-sec .file-name div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-name-sec svg {
  margin-right: 7px;
}

.file-name-sec button {
  padding: 0px;
  margin: 0;
  border: 0;
  background: none;
  outline: 0;
  box-shadow: inherit;
}

.file-name-sec button svg {
  margin-right: 0px;
}

.file-name-sec .file-name span {
  font-size: 17px;
  color: #35254D;
}

.file-name {
  font-size: 12px;
  align-items: center;
  color: rgb(128, 0, 0) !important;
}

.gap {
  margin-right: 10px;
}

.submit-button {
  background-color: #151516;
  color: white;
  border: none;
  padding: 13px 23px;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 20px;
  border-radius: 8px;
  float: right;
  transition: background-color 0.3s, color 0.3s;
}

.submit-button:hover {
  background-color: white;
  color: #151516;
  border: 2px solid #151516;
  border-radius: 8px;
}

.contactForm-head1 {
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
}

.contactForm-head2 {
  font-weight: 400;
  font-size: 18px;
}

.separator {
  border-top: 1px solid black;
}

.transaction-field-container {

  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}

.transaction-field-container h3 {
  margin-bottom: 10px;
}


.filters-wrapper {
  display: flex;
  justify-content: center;
  max-width: 1170px;
  margin: 0 auto;
}

.filters-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}



.filters-container div {
  width: auto;
  align-items: end;
  gap: 20px;
}

.date-filters {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.date-filters>div {
  margin-right: 3px;
}

.filters-container label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.filters-container input[type="text"],
.filters-container input[type="date"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
}

.filters-container input[type="text"] {
  width: 264px;
  height: 30px;
}

.filters-container>div:not(:first-child) {
  margin-top: 10px;
}

@media screen and (max-width: 1170px) {
  .date-filters {
    flex-direction: column;
  }

  .date-filters>div {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

table {
  width: 100%;
  text-align: left;
  max-width: 1170px;
  margin: 16px auto 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border-spacing: 0px !important;
  border: #DEE2E6 1px solid;
  overflow: hidden;
}

thead tr {
  background-color: #F3F4F6;
  color: #1F2937;
}

thead tr th {
  padding: 16px 10px;
  font-weight: 600;
  border-bottom: 1px solid #E5E7EB;
}

tbody tr {
  background-color: #FFFFFF;
}

tbody tr td {
  padding: 16px 10px;
  border-bottom: 1px solid #E5E7EB;
}

tbody tr:last-child td {
  border-bottom: 1px solid #E5E7EB;
}

table th, table td {
  padding: 8px;
  text-align: left;
}

.transaction-field-container h3 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-top: 3%;
  margin-bottom: -2%;
}

.container {
  position: relative;
}

.pagination-container {
  float: right;
  margin: 3%;
}

.b-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: none;
  height: 100%;
  z-index: 9999;
  text-align: center;
  display: flex;
  display: none;
}

.h-100 {
  height: 100% !important;
}

.d-flex {
  display: flex !important;
}

.b-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: none;
  height: 100%;
  z-index: 9999;
  text-align: center;
  display: block;
}

.h-100 {
  height: 100% !important;
}

.d-flex {
  display: flex !important;
}

.b-loader .site-loader {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: rgba(250, 250, 250, .8);
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: 15px;
}

.bounceball:before {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #2980b9;
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: bounce 500ms alternate infinite ease;
  animation: bounce 500ms alternate infinite ease;
}

.loading .text {
  color: #2980b9;
  display: inline-block;
  margin-left: 5px;
  text-transform: uppercase;
  font-weight: 600;
}

@-webkit-keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    -webkit-transform: scaleX(2);
    transform: scaleX(2);
  }

  35% {
    height: 15px;
    border-radius: 50%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  100% {
    top: 0;
  }
}

@keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    -webkit-transform: scaleX(2);
    transform: scaleX(2);
  }

  35% {
    height: 15px;
    border-radius: 50%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  100% {
    top: 0;
  }
}

.wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


.css-1o5tjct {
  width: 85% !important;
  margin-top: 30px !important;
}



.makeStyles-root-4 {
  border-radius: 0 !important;
  background-color: transparent !important;
}

.container.all-steps {
  box-shadow: none !important;

}

.container.all-steps.final {
  box-shadow: none !important;
  background: conic-gradient(from -60.27deg at 116.22% 27.56%, #E7D8FF 0deg, #F5F0FF 22.22deg, #F2EAFF 52.73deg, #FBF9FF 112.87deg, #F9F5FF 175.64deg, #FFFFFF 283.65deg, #E7D8FF 360deg), #FFFFFF;
  border-radius: 12px;

}

.error {
  font-size: 12px;
  padding: 0 1rem;
  color: red;
}


@media screen and (max-width: 991px) {
  .css-1o5tjct {
    width: 100% !important;
  }


  .css-1o5tjct {
    display: flex;
    justify-content: center;
  }

}

@media screen and (max-width: 767px) {


  .css-1o5tjct {
    display: inherit;
    justify-content: center;
    flex-grow: 1;
  }

  .heading1 {
    font-size: 17px;
  }

  .container.all-steps .succ-regi {
    padding: 0 !important;
  }
}

@media screen and (max-width: 550px) {
  .input-coll {
    width: 100%;
  }

  .container h2 {
    font-size: 16px;
  }

  .heading-ico img {
    max-width: 29px;
  }

  .Btn1div {
    margin-top: 5px;
  }

  .Btn1div button {
    min-height: 40px;
    max-height: 40px;
  }

  .chooseFile {
    font-size: 12px;
    padding: 20px;
    min-height: 150px;
  }

  .termsConditions label {
    font-size: 10px;
    line-height: 11px;
  }


  .rc-anchor-normal .rc-anchor-checkbox-label {
    width: auto;
  }

  .rc-anchor-checkbox-label {
    font-size: 11px;
  }

  .rc-anchor-normal .rc-anchor-content {
    width: auto;
  }

  .rc-anchor-normal {
    height: 74px;
    width: 220px;
  }

  .rc-anchor-normal .rc-anchor-pt {
    right: 80px;
    width: 66px;
  }

  .all-steps {
    overflow: hidden;
  }

  .chooseFile small {
    font-size: 12px;
  }

}

@media screen and (max-width: 420px) {


  .succ-regi h1 {
    font-size: 26px !important;
  }

  .succ-regi p {
    font-size: 14px !important;
  }

  .succ-regi p br {
    display: none;
  }
}

.client-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0 0;
}

.client-logo img {
  max-width: 150px;

}

.terms-button {
  /* Buttons / Large / Primary */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 10px;

  width: 122px;
  height: 56px;

  /* Main/Black */
  background: #161616;
  border-radius: 1000px;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 8px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.terms-button:hover {
  background-color: #333333;
}

.terms-button:active {
  background-color: #0d0d0d;
}

.text-center {
  text-align: center;
}

.all-steps .text-center h4 {
  color: #35254D;
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 15px;
}

.text-center span {
  color: gray;
  font-size: 14px;
}

.all-steps .FirstPgBtn {
  width: 50%;
  display: block;
  margin: 0;
  padding: 20px;
  background: #000;
  border-radius: 180px;
  color: #fff;
}

.all-steps .FirstPgBtn:hover {
  background: #35254D;
  cursor: pointer;
}

.all-steps .lets-go-btn {
  width: 160px;
  display: block;
  margin: 50px 0 0 0;
  padding: 20px;
  background: #000;
  border-radius: 180px;
  color: #fff;
  font-size: 16px;
}

.all-steps .lets-go-btn:hover {
  background: #35254D;
  cursor: pointer;
}

.all-steps .continue-sec {
  display: flex;
  justify-content: end;
}

.all-steps .continue-btn {
  width: 350px;
  display: block;
  margin: 50px 0 0 0;
  padding: 20px;
  background: #000;
  border-radius: 180px;
  color: #fff;
  font-size: 16px;
}

.all-steps .continue-btn:hover {
  background: #35254D;
  cursor: pointer;
}

.app-time-head {
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 14px;
  background-color: #F9F5FF;
  padding: 5px 20px;
  margin: 35px 0 12px 0;
}

.app-time-head p {
  font-size: 14px;
}

.application-info {
  border: 1px solid #D4CDDF !important;
  border-radius: 20px;
  padding: 20px 24px;
}

.application-info .main-ton {
  color: #35254D;
  font-weight: 500;
  font-size: 15px;
  font-family: "IBM Plex Mono", monospace;
  text-transform: uppercase;
  margin-bottom: 20px;
  display: block;
  /* margin-bottom: 10px; */
}

.application-info .disk {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
}

.application-info .disk .disk-det {
  color: #35254D;
  font-size: 14px;
  font-weight: 500;
}

.fs-14 {
  font-size: 14px;
}

.application-info .pur-circle {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 180px;
  background: #35254D;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  margin-right: 10px;
}


.container.all-steps .succ-regi {
  border: 0 none;
  padding: 50px;
  box-sizing: border-box;
  width: 89%;
  max-width: 780px;
  margin: 50px auto;
  position: relative;
  text-align: center;
}

.succ-regi .text {
  margin-bottom: 40px;
}

.succ-regi h1 {
  font-size: 32px;
  color: #35254D;
  margin-bottom: 5px;
}

.succ-regi p {
  font-size: 16px;
  color: #9A92A6;
  padding: 0;
  margin: 0;
}


.succ-regi button {
  width: auto;
  display: block;
  margin: 0 auto 0 auto;
  padding: 15px 30px;
  background: #000;
  border-radius: 180px;
  color: #fff;
  font-size: 16px;
}

.succ-regi button:hover {
  background: #35254D;
  cursor: pointer;
}

.owner-name-tag {
  border-radius: 15px;
  border: 1px solid rgb(212, 212, 212);
  padding: 5px 10px;
  cursor: pointer;
}

.owner-name-tag-active {
  border-radius: 15px;
  border: 1px solid rgb(22, 17, 17) !important;
  padding: 5px 10px;
  cursor: pointer;
}

.owner-tag-out {
  display: flex;
  justify-content: flex-start;
  gap: 1px;
}

.add-owner-btn {
  border-radius: 50%;
  padding: 0px 8px;
  border: 1px solid gray;
  font-size: 23px;
  cursor: pointer;
}

.react-responsive-modal-modal {
  background: conic-gradient(from -60.27deg at 116.22% 27.56%, #E7D8FF 0deg, #F5F0FF 22.22deg, #F2EAFF 52.73deg, #FBF9FF 112.87deg, #F9F5FF 175.64deg, #FFFFFF 283.65deg, #E7D8FF 360deg), #FFFFFF !important;
  border-radius: 12px;
}

.react-responsive-modal-modal .modal-content {
  padding: 80px 20px;
  text-align: center;
}

.react-responsive-modal-modal .modal-content svg {
  color: #896EB5;
}

.react-responsive-modal-modal .modal-content h3 {
  color: #35254D;
  font-size: 33px;
  margin: 10px 0;
}

.react-responsive-modal-modal .modal-content p {
  margin: 0 0 30px 0;
}

.react-responsive-modal-modal .modal-content span {
  width: 70px;
  height: 70px;
  border-radius: 180px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(144.92deg, #FFFFFF -0.07%, rgba(199, 164, 255, 0.59) 12.93%, rgba(238, 228, 255, 0.56) 58.56%, #FFFFFF 90.52%);
}

.react-responsive-modal-modal .modal-content button {
  padding: 10px 30px;
  margin: 5px;
  font-size: 17px;
  background: none;
  border-radius: 180px;
  color: #35254D;
  border: #35254D 1px solid;
  outline: inherit;
  cursor: pointer;
}

.react-responsive-modal-modal .modal-content button:hover {
  background: #35254D;
  color: #fff;
  border: #35254D 1px solid;
}

.react-responsive-modal-modal .modal-content button:last-child {
  background: #35254D;
  color: #fff;
  border: #35254D 1px solid;
}

.react-responsive-modal-modal .modal-content button:last-child:hover {
  background: none;
  color: #35254D;
  border: #35254D 1px solid;
}

@media screen and (min-width: 767px) {
  .react-responsive-modal-modal {
    min-width: 500px;
  }

}

@media screen and (max-width: 991px) {
  .ownership-text {
    display: block;
    margin-bottom: 20px;
  }

  .ownership-text button {
    margin: 8px 6px 0 0;
  }


}

@media screen and (min-width: 767px) {}

@media screen and (max-width: 767px) {


  .app-time-head {
    align-items: flex-start;
  }

  .backgroundColor.padding.borderRadius {
    line-height: 10px !important;
  }

  .app-time-head svg {
    min-width: 15px !important;
    min-height: 15px !important;
    margin-top: 15px;
  }

}

@media screen and (max-width: 650px) {}

@media screen and (max-width: 550px) {
  .container.all-steps {
    padding: 30px;
  }


  .all-steps .text-center h4 {
    font-size: 18px;
  }

  .all-steps .FirstPgBtn {
    padding: 13px 20px;
  }

  .react-responsive-modal-modal .modal-content {
    padding: 50px 0px;
  }

  .react-responsive-modal-modal .modal-content h3 {
    font-size: 28px;
  }

  .react-responsive-modal-modal .modal-content button {
    padding: 10px 30px;
    font-size: 14px;
  }
}

@media screen and (max-width: 460px) {
  .container.all-steps {
    padding: 30px 20px;
  }




}

@media screen and (max-width: 380px) {
  .react-responsive-modal-modal .modal-content {
    padding: 30px 0px;
  }
}

@media screen and (max-width: 320px) {}

.recaptcha-container {
  display: inline-block;
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.recaptcha-container>div {
  transform: scale(0.77);
  transform-origin: 0 0;
}

@media (max-width: 768px) {
  .recaptcha-container>div {
    transform: scale(0.6);
  }
}

@media (max-width: 480px) {
  .recaptcha-container>div {
    transform: scale(0.8);
  }
}



/* Accordian Css PSR*/
.tabs {
  border: #D4CDDF 1px solid;
  border-radius: 24px;
}

.tab {
  width: 100%;
  color: white;
  overflow: hidden;
  border-bottom: #D4CDDF 1px solid;
}

.tab input[type="checkbox"] {
  display: none;
}

.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  background: #F9F5FF;
  font-weight: bold;
  cursor: pointer;
  color: #35254D;
  align-items: center;
  position: relative;
}

.tab:nth-child(1) .tab-label {
  border-radius: 24px 24px 0 0;
}

.tab-label span {
  font-size: 16px;
  font-weight: 600;
}

.tab-label span small {
  font-size: 14px;
  display: block;
  font-weight: 500;
  font-family: "IBM Plex Mono", monospace;
}

.tab-label::after {
  content: "❯";
  width: 52px;
  height: 52px;
  text-align: center;
  transition: all 0.35s;
  background: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);

}

.tab-content {
  max-height: 0;
  padding: 0 1em;
  background: white;
  transition: all 0.35s;
  color: #35254D;
}




input:checked+.tab-label::after {
  transform: rotate(-90deg);
}

input:checked~.tab-content {
  max-height: max-content;
  padding: 1em 2em;
}

.w-100 {
  width: 100% !important;
}

.add-owner {
  border: 0;
}

.add-owner .tab-label {

  width: inherit !important;
  text-align: center;
  display: block;
  background: #fff;
  border-radius: 0 0 24px 24px;
  border: 0;
}

.add-owner .tab-label:hover {
  background: #F9F5FF;
}

.add-owner .tab-label::after {
  display: none;
}

.add-all-owner {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #35254D;
  background: #F9F5FF;
  padding: 10px 20px;
  width: max-content;
  border-radius: 12px;
  margin-bottom: 15px;
}

.add-all-owner svg {
  margin-right: 7px;
}

button.delete {
  position: absolute;
  right: 95px;
  top: 42px;
  border: 0;
  background: none;
  cursor: pointer;
  z-index: 2;
}

button.delete svg:hover {
  fill: #35254D;
}

button.delete svg:hover path {
  fill: #35254D;
}

button.delete svg:hover plygon {
  fill: #35254D;
}

button.delete svg:hover circle {
  fill: #35254D;
}



@media screen and (max-width: 620px) {
  .add-all-owner {
    width: auto;
    align-items: self-start;
  }

  .add-all-owner svg {
    width: 30px;
    margin-top: 5px;
  }

  .tab-label::after {
    width: 30px;
    height: 30px;
    margin-right: -15px;
    margin-bottom: -23px;
  }

  button.delete {
    right: 11px;
    top: 14px;
  }
}

.has-error {
  border: 1px solid red !important;
}





/* Steps CSS Start PSR */


.steps {
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  table-layout: fixed;
  width: 100%;
  color: #35254D;
}

.steps span {
  display: flex;
  align-items: center;
}

.steps img {
  margin-right: 5px;
}

.steps>.step {
  position: relative;
  display: flex;
  text-align: center;
  font-size: 0.875rem;
  color: #35254D;
  margin: 0 20px;
  border: 1px solid #D4CDDF;
  padding: 8px 20px;
  border-radius: 180px;
  font-weight: 500;
  background: #E5E0EF;
}

.steps>.step small {
  font-size: 0.875rem;
}

.steps>.step:after {
  content: "";
  position: absolute;
  display: block;
  width: 12px;
  height: 1px;
  top: 1rem;
  background: #867C94;
  border-radius: 180px;
  left: 108%;
  transform: translate(-8%);
}

.react-pdf__Document,
.react-pdf__Page,
.react-pdf__Page__canvas {
  display: block;
  user-select: none;
  width: 100%;
  /* Default to 100% width for responsiveness */
  height: 70%;
  /* Adjust the height to maintain aspect ratio */
  border-radius: 10px;
}


@media (min-width: 768px) {

  .react-pdf__Document,
  .react-pdf__Page,
  .react-pdf__Page__canvas {
    width: 1224px;
    height: 1584px;
  }
}



.steps>.step:last-child:after {
  display: none;
}

.steps .step.complete {
  color: #35254D;
}

.steps>.step.complete:after {
  background: #867C94;
}

.steps>.step.active {
  background: #F9F5FF;

}

.steps {
  margin-bottom: 3em;
}

.done {
  display: none;
}

.step.complete .done {
  display: block;
}

.step.complete .normal {
  display: none;
}


@media screen and (max-width: 1199px) {
  .steps>.step small {
    display: none;
  }

  .steps>.step:after {
    left: 114%;
  }

}

@media screen and (max-width:991px) {
  .steps>.step {
    padding: 8px 15px;
  }
}

@media screen and (max-width:767px) {
  .steps .step-text {
    display: none;
  }

  .steps>.step {
    padding: 0;
    height: 35px;
    width: 35px;
    justify-content: center;
  }

  .steps img {
    margin-right: 0;
  }

  .steps>.step:after {
    left: 146%;
  }
}

@media screen and (max-width:450px) {

  .steps {
    justify-content: space-around;
  }

  .steps>.step {
    margin: 0;
  }

  .steps>.step:after {
    left: 158%;
  }
}

@media screen and (max-width:380px) {
  .steps>.step:after {
    left: 144%;
  }
}

@media screen and (max-width:350px) {
  .steps>.step:after {
    left: 136%;
  }
}

@media screen and (max-width:320px) {
  .steps>.step:after {
    left: 127%;
  }
}

/* Steps CSS End PSR */


/* remove arrowa from number start*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* remove arrowa from number end */

.css-13cymwt-control {
  border: 0 !important;
  background: none;
  padding: 0;
}

.basic-single.fields {
  padding: 0 5px;
}

.basic-single.fields .css-1u9des2-indicatorSeparator {
  display: none;
}

.basic-single.fields .css-t3ipsp-control:hover {
  outline: none !important;
  border: 0 !important;
  box-shadow: none !important;
}


.digi-sign h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #35254D;
}

.digi-sign svg {
  margin-right: 5px;
}

.digi-sign {
  background: conic-gradient(from-60.27deg at 116.22% 27.56%, #E7D8FF 0deg, #F5F0FF 22.22deg, #F2EAFF 52.73deg, #FBF9FF 112.87deg, #F9F5FF 175.64deg, #FFFFFF 283.65deg, #E7D8FF 360deg), #FFFFFF;
  margin: 0 auto;
  padding: 20px;
  border-radius: 15px;
  margin-top: 20px;
}

.digi-sign button {
  background: #35254D;
  padding: 11px 35px;
  border-radius: 180px;
  color: #fff;
  border: #35254D 1px solid;
  margin: 4px;
  cursor: pointer;
  font-size: 16px;
}

.digi-sign button:hover {
  background: none;
  color: #35254D;
}

.sigCanvas {
  border-radius: 20px;
  border: #D4CDDF 2px dashed;
}

@media screen and (min-width:991px) {
  .digi-sign {
    background: conic-gradient(from -60.27deg at 116.22% 27.56%, #E7D8FF 0deg, #F5F0FF 22.22deg, #F2EAFF 52.73deg, #FBF9FF 112.87deg, #F9F5FF 175.64deg, #FFFFFF 283.65deg, #E7D8FF 360deg), #FFFFFF;
    max-width: 800px;
  }

  .sigCanvas {
    width: 100%;
    height: 350px;
  }
}

@media screen and (max-width:990px) {
  .digi-sign {
    background: conic-gradient(from -60.27deg at 116.22% 27.56%, #E7D8FF 0deg, #F5F0FF 22.22deg, #F2EAFF 52.73deg, #FBF9FF 112.87deg, #F9F5FF 175.64deg, #FFFFFF 283.65deg, #E7D8FF 360deg), #FFFFFF;
    max-width: 75%;
    padding: 20px;
  }

  .sigCanvas {
    width: 100%;
    height: 350px;
  }

}

@media screen and (max-width:450px) {
  .sigCanvas {
    width: 100%;
    height: 220px;
  }

  .digi-sign button {
    padding: 8px 25px;
    font-size: 14px;
  }

  .digi-sign h2 {
    font-size: 20px;
  }

  .digi-sign h2 svg {
    width: 25px;
  }
}


.agreement-main {
  text-align: center;
  padding: 40px 0;
  background-color: #f9f9fc;
  /* Subtle background for better contrast */
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  /* Adds depth */
  max-width: 1000px;
  margin: 50px auto 0 auto;
}

.agreement-main h2 {
  margin: 0 0 30px 0;
  font-family: "IBM Plex Mono", monospace;
  font-size: 28px;
  color: #2b2b36;
  /* Darker color for modern look */
}

.agreement-main p {
  font-size: 12px;
  margin: 0 100px 10px 100px;
}

.agreement-main .pdf-main {
  display: inline-block;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  height: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.react-pdf__Document,
.react-pdf__Page {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .agreement-main .pdf-main {
    width: 100%;
    height: auto;
  }

  .react-pdf__Page {
    width: 100%;
    height: auto;
  }
}

.agreement-main ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 40px 0 0 0;
}

.zoom-controls {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.zoom-controls button {
  margin: 5px;
  padding: 10px 14px;
  background-color: #61C699;
  border: none;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.zoom-controls button:hover {
  background-color: #41A478;
}

.agreement-main ul li:nth-child(2) {
  width: 25px;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* previous-btn idle state */
.previous-btn, .primary-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* previous-btn styles */
.previous-btn {
  background: #161616;
}

.previous-btn:hover {
  background: #3A3A3A;
}

.previous-btn:active {
  background: #3A3A3A;
}

.previous-btn:focus {
  outline: none;
  border: 2px solid #61C699;
}

.previous-btn.disabled {
  background: #3A3A3A;
  opacity: 0.5;
  cursor: not-allowed;
}

/* primary-btn styles */
.primary-btn {
  background: #61C699;
}

.primary-btn:hover {
  background: #41A478;
}

.primary-btn:active {
  background: #41A478;
}

.primary-btn:focus {
  outline: none;
  border: 2px solid #161616;
}

.zoom {
  background-color: #05c99587;
  border: none;
  border-radius: 4px;
  color: #121212;
  cursor: pointer;
  padding: 8px 10px;
}

.full-screen {
  background-color: #05c99587;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  padding: px;
  margin-left: 10px
}

.controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 5 !important;
}

.file-viewer {
    height: 80vh;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}